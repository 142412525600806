<template>
  <v-container v-cloak fluid grid-list-lg>
    <v-snackbar v-model="snack" :color="snackColor" :timeout="3000">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>

    <v-layout v-if="isSubscribed && !loading">
      <v-flex xs12>
        <v-card>
          <v-container fluid>
            <v-row dense>
              <v-flex sm6 xs12>

                <v-card>
                  <v-card-subtitle>
                    <v-icon left>mdi-account</v-icon>

                    Name:
                  </v-card-subtitle>
                  <v-card-title>
                    {{ this.name }}
                  </v-card-title>
                </v-card>
              </v-flex>
              <v-flex sm6 xs12>

                <v-card>
                  <v-card-subtitle>
                    <v-icon left>mdi-at</v-icon>

                    Email:
                  </v-card-subtitle>
                  <v-card-title>
                    {{ this.serverEmail }}
                  </v-card-title>
                </v-card>
              </v-flex>


              <v-flex sm6 xs12>

                <v-card>
                  <v-card-subtitle>
                    <v-icon left>mdi-calendar</v-icon>
                    Expiry (YYYY-MM-DD HH:MM:SS):
                  </v-card-subtitle>
                  <v-card-title>
                    {{ this.expiry }}
                  </v-card-title>
                </v-card>
              </v-flex>

              <v-flex sm6 xs12>
                <v-card>
                  <v-card-subtitle>
                    <v-icon left>mdi-currency-inr</v-icon>
                    Subscribed Plan:
                  </v-card-subtitle>
                  <v-card-title>
                    {{ this.subID }}
                  </v-card-title>
                </v-card>
              </v-flex>
              <v-flex xs12>
                <v-card>
                  <v-card-subtitle>
                    <v-icon left>mdi-check-bold</v-icon>
                    Subscription Status:
                  </v-card-subtitle>
                  <v-card-title v-if="this.isSubscribed" class="green--text">
                    ACTIVE
                  </v-card-title>
                  <v-card-title v-else class="red--text">
                    INACTIVE
                  </v-card-title>
                </v-card>
              </v-flex>
            </v-row>
          </v-container>
        </v-card>

        <v-data-table
            :headers="headers"
            :items="desserts"
            :items-per-page="10"
            :loading="loading"
            :sort-desc="[true, false]"
            class="elevation-1"
            sort-by="expiry_date"

        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Details of your subscriptions</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.price="props">
            ₹ {{ props.item.price }}/-
          </template>


        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout v-else row wrap>
      <v-flex xs12>
        <v-alert :value="true" type="success">
          <p class="display-1">FREE Plan</p>
          <p class="subheading">Subscription valid till NEET exam</p>
          <p
              class="title deep-orange--text pa-2"
              style="background-color:white;"
          >Access only to Today's Test</p>
          <p class="subheading">Limited Practise MCQs per day.</p>
          <p class="subheading">Bookmark and revise your favorite MCQs</p>
          <p class="subheading">Limited Support</p>
        </v-alert>
      </v-flex>

      <v-flex md3 sm6 xs12>
        <v-card>
          <v-img class="white--text" height="200px" style="background-color:#5a10fb">
            <v-container fill-height fluid>
              <v-layout>
                <v-flex xs12>
                  <p class="display-1 text-center">NEET Plan</p>
                  <p class="heading text-center">
                    <!--                                        ₹ 5000/- till NEET*-->
                    <strong>₹ 10500/-</strong> till NEET MDS 2024*<br>
                    (Access to all content available)
                  </p>
                  <div class="text-center">
                    <v-btn class="white--text text-center" outlined @click="showDialog(1)">POPULAR
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-img>
          <div class="text-center pa-2">
            <p class="grey--text headline">One time payment</p>
            <p class="title">Subscription valid till NEET exam</p>
            <p class="title">Access to all upcoming tests</p>
            <p class="title  green--text">Access to all previous tests</p>
            <p class="title">Access Full length NEET tests</p>
            <p class="title">Access 5 year wise tests</p>
            <p class="title">Not auto-renewing</p>
            <p class="title">Unlimited practise questions</p>
            <p class="title">Bookmark and revise your favorite MCQs</p>
            <p class="title">Priority Support</p>
            <p class="title">Your doubts solved by subject experts</p>
            <p class="grey--text">*Taxes extra</p>

            <br/>
            <br/>
            <v-card-actions>
              <v-btn class="white--text" color="indigo" outlined @click="showDialog(1)">
                Order Now
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
      <v-flex md3 sm6 xs12>
        <v-card>
          <v-img class="white--text" height="200px" style="background-color:#5a10fb">
            <v-container fill-height fluid>
              <v-layout>
                <v-flex xs12>
                  <p class="display-1 text-center">6 Month Plan</p>
                  <p class="heading text-center">
                    <!--                                        ₹ 2550/- for 180 days*-->
                    <strong>₹ 4000/-</strong> for 180 days*
                    <br>
                    (180 past days + 180 future days)
                  </p>
                  <div class="text-center">
                    <v-btn class="green--text text-center" outlined @click="showDialog(2)">Buy Now
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-img>
          <div class="text-center pa-2">
            <p class="grey--text headline">One time payment</p>
            <p class="title">Subscription valid for <span class="red--text"> 180 days</span></p>
            <p class="title">Access to all upcoming tests</p>
            <p class="title  green--text">Access to previous tests within 180 days</p>
            <p class="title">Access Full length NEET tests</p>
            <p class="title">Access 5 year wise tests</p>
            <p class="title">Not auto-renewing</p>
            <p class="title">Unlimited practise questions</p>
            <p class="title">Bookmark and revise your favorite MCQs</p>
            <p class="title">Priority Support</p>
            <p class="title">Your doubts solved by subject experts</p>
            <p class="grey--text">*Taxes extra</p>

            <br/>
            <br/>
            <v-card-actions>
              <v-btn class="white--text" color="indigo" outlined @click="showDialog(2)">
                Order Now
              </v-btn>
            </v-card-actions>

          </div>
        </v-card>
      </v-flex>
      <v-flex md3 sm6 xs12>
        <v-card>
          <v-img class="white--text" height="200px" style="background-color:#5a10fb">
            <v-container fill-height fluid>
              <v-layout>
                <v-flex xs12>
                  <p class="display-1 text-center">3 Month Plan</p>
                  <p class="heading text-center">
                    <!--                                        ₹ 1350/- for 90 days*-->
                    <strong>₹ 2550/- </strong>for 90 days*
                    <br>
                    (90 past days + 90 future days)
                  </p>
                  <div class="text-center">
                    <v-btn class="green--text text-center" outlined @click="showDialog(3)">Buy Now
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-img>
          <div class="text-center  pa-2">
            <p class="grey--text headline">One time payment</p>
            <p class="title">Subscription valid for <span class="red--text"> 90 days</span></p>
            <p class="title">Access to all upcoming tests</p>
            <p class="title  green--text">Access to previous tests within 90 days</p>
            <p class="title">Access Full length NEET tests</p>
            <p class="title">Access 5 year wise tests</p>
            <p class="title">Not auto-renewing</p>
            <p class="title">Unlimited practise questions</p>
            <p class="title">Bookmark and revise your favorite MCQs</p>
            <p class="title">Priority Support</p>
            <p class="title">Your doubts solved by subject experts</p>
            <p class="grey--text">*Taxes extra</p>

            <br/>
            <br/>
            <v-card-actions>
              <v-btn class="white--text" color="indigo" outlined @click="showDialog(3)">
                Order Now
              </v-btn>
            </v-card-actions>

          </div>
        </v-card>
      </v-flex>

      <v-flex md3 sm6 xs12>
        <v-card>
          <v-img class="white--text" height="200px" style="background-color:#5a10fb">
            <v-container fill-height fluid>
              <v-layout>
                <v-flex xs12>
                  <p class="display-1 text-center">Monthly Plan</p>
                  <p class="heading text-center">
                    <!--                                        ₹ 499/month* **-->
                    <strong>₹ 699</strong>/month* **
                    <br>
                    (28 past days + 28 future days)
                  </p>
                  <div class="text-center">
                    <v-btn class="green--text text-center" outlined @click="showDialog(4)">Buy Now
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-img>
          <div class="text-center pa-2">
            <p class="grey--text subtitle-2">Pay as you go</p>
            <p class="title">Subscription valid till 28 days</p>
            <p class="title">Access to all upcoming tests</p>
            <p class="title red--text">Access to previous tests within 28 days</p>
            <p class="title">Not auto-renewing</p>
            <p class="title">Unlimited practise questions</p>
            <p class="title">Bookmark and revise your favorite MCQs</p>
            <p class="grey--text">*Taxes extra</p>
            <p class="grey--text">**Cost will increase as exam approaches</p>

            <br/>
            <v-card-actions>
              <v-btn class="white--text" color="indigo" outlined @click="showDialog(4)">
                Order Now
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </v-layout>

    <template>
      <v-layout justify="center" row>
        <v-dialog v-model="dialog" max-width="600px" persistent>
          <v-card>
            <v-toolbar
                dark
            >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title> Please confirm your details.:</v-toolbar-title>


            </v-toolbar>
            <!--                        <v-card-title>-->
            <!--                            <span class="headline red&#45;&#45;text"> Subscriptions closed for NEET MDS 2020. </span>-->
            <!--                            <p class="subheading">-->
            <!--                                As the neet exams are just a few days away, we are closing new subscriptions.<br>-->
            <!--                                We shall resume the Online Platform from the Month of January 2020 for new members.<br>-->
            <!--                                For any query, requests reach out to us at <a href="mailto:dentalguru2016@gmail.com">dentalguru2016@gmail.com</a>-->
            <!--                            </p>-->
            <!--                        </v-card-title>-->
            <!--                        <v-card-actions>-->
            <!--                            <v-btn @click="dialog = false" color="blue darken-1">Close</v-btn>-->

            <!--                        </v-card-actions>-->
            <v-card-title>
              <span class="headline">Proceeding to Payment Gateway.</span>
              <p class="subheading">Please check your details. Email and SMS will be sent.</p>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field v-model="email" label="E-mail" required/>
                <v-text-field
                    v-model="mobile"
                    :rules="mobileRules"
                    label="Indian Mobile Number"
                    required
                />

                <p>
                  You agree to the
                  <a
                      href="https://dentalpockets.com/tnc.html"
                      target="_blank"
                  >Terms and Conditions</a>
                </p>
                <v-checkbox
                    v-model="checkbox"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    label="You have read and understood the Terms and Condition."
                    required
                />
                <v-btn class="mr-4" color="blue darken-1" text @click="dialog = false">Close</v-btn>
                <v-btn class="white--text" color="indigo darken-1" text @click="validate()">PROCEED
                </v-btn>
              </v-form>

            </v-card-text>

          </v-card>
        </v-dialog>
      </v-layout>
    </template>
  </v-container>
</template>


<script>
import {APIService} from "@/APIService";

const apiService = new APIService();
export default {
  metaInfo: {
    title: 'Subscribe',
    titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {
        name: 'description',
        content: "NEET, NBDE, Dental Exams, PGI, JIPMER, preparation of dental exams now easy"
      }
    ]
  },
  data: () => ({

    headers: [
      {
        text: 'Subscription Type',
        align: 'start',
        sortable: false,
        value: 'subName',
      },
      {text: 'Amount', value: 'price'},
      {text: 'Created', value: 'created_at'},
      {text: 'Expiry', value: 'expiry_date'},
      {text: 'Notes', value: 'comments'}
    ],
    desserts: [],
    name: null,
    serverEmail: null,
    subID: null,
    expiry: null,

    loading: false,

    snack: false,
    snackColor: '',
    snackText: '',


    valid: true,
    dialog: false,
    mobile: "",
    purchase: "",
    mobileRules: [
      v => (v && v.length === 10) || "Mobile Number must be of 10 characters"
    ],
    checkbox: false
  }),
  mounted() {
    this.$nextTick(function () {
      this.$store.commit("setShowProgress", true);


      apiService.getSubscriptionsFromServer().then(data => {

        // eslint-disable-next-line no-console
        // console.log(data);
        // noinspection JSUnresolvedVariable
        this.desserts = data.subscriptions;
        this.serverEmail = data.email;
        this.name = data.name;
        // noinspection JSUnresolvedVariable
        this.subID = data.subName;
        this.expiry = data.expiry;

        this.$store.commit("setSubscriptionsStatus", data.subscribed);

        this.loading = false;

        this.showProgress = false;
        this.$store.commit("setShowProgress", false);

      });


    });

  },
  computed: {
    email() {
      return this.$store.state.email;
    },
    isSubscribed() {
      return this.$store.getters.getSubscriptionStatus;
    }

  },
  methods: {
    showDialog(val) {
      if (val === 1) {
        this.purchase = 99999;
      } else if (val === 2) {
        this.purchase = 99998;
      } else if (val === 3) {
        this.purchase = 99997;
      } else if (val === 4) {
        this.purchase = 142;
      }
      this.dialog = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.checkAndSubmit();
        // console.log("Form Valid");
      } else {
        // eslint-disable-next-line no-console
        console.log("Form IN Valid");
      }
    },
    checkAndSubmit() {
      let options = {
        tid: this.purchase,
        email: this.email,
        mob: this.mobile,
        uid: localStorage.getItem("uid"),
        uname: this.name
      };

      if (this.isSubscribed) {
        this.snackColor = 'error';
        this.snackText = 'You already have an active subscription';
        this.snack = true;

      } else {

        this.snackColor = 'info';
        this.snackText = 'Redirecting you to payment gateway';
        this.snack = true;
        this.$store.commit("setShowProgress", true);

        apiService.createPaymentRequest(options).then(data => {
          // eslint-disable-next-line no-console
          // console.log(data);
          if (data !== 401) {
            window.location = data.longurl;
          } else {
            let msg = data.Error;
            this.snackColor = 'error';
            this.snackText = 'Some error occurred while trying to redirect. Payment Gateway may be down. Please try later or Contact Admin.\n' + msg;
            this.snack = true;
          }
          this.$store.commit("setShowProgress", false);

        });

        //
        // axios
        //     .post(
        //         "https://duparedentalclinic.com/be-webapp/createPaymentRequest",
        //         options,
        //         [{withCredentials: true, crossdomain: true}]
        //     )
        //     .then(function (response) {
        //       // console.log(response.data.longurl);
        //       // noinspection JSUnresolvedVariable
        //       window.location = response.data.longurl;
        //     })
        //     .catch(function (error) {
        //       // eslint-disable-next-line no-console
        //       console.log(error);
        //     });
      }


    }
  }
};
</script>
